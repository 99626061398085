
import InventoryEventGroup from "@/services/InventoryEventGroup.js";
import { json2excel } from "js2excel";

export default class DownloadService {
  constructor(name) {
    if(name){
      this.name = name;
    }
    else{
      this.name = "download"
    }
  }

  downloadInventorySummary(products, dateRanges, eventService, costAdjustmentService, startBalanceService, endBalanceService) {
    
    const csv = [];
    if(products && products.length > 0){
      products.filter(p => p.visible).forEach(product => {
        const row = { product: product.name }
        const startBalance = startBalanceService.getBalanceByProduct(product);
        row['startDate'] = startBalanceService.date;
        row['startQuantity'] = startBalance.quantity;
        row['startValue'] = startBalance.remainingValue;

        if(dateRanges && dateRanges.length > 0){
          dateRanges.forEach(dateRange => {
            const group = new InventoryEventGroup(
              product,
              dateRange.startTime,
              dateRange.endTime
            );
            const groupedEvents = eventService.getGroupedEvents(group, costAdjustmentService);
        
            row[`${dateRange.label}_value`] = groupedEvents.totalValue;
            row[`${dateRange.label}_quantity`] = groupedEvents.totalQuantity;
          })
        }
        
        const endBalance = endBalanceService.getBalanceByProduct(product);
        row['endDate'] = endBalanceService.date;
        row['endQuantity'] = endBalance.quantity;
        row['endValue'] = endBalance.remainingValue;

        csv.push(row);
      })
    }

    try {
      json2excel({
        data: csv,
        name: this.name,
        formateDate: "yyyy-mm-dd"
      });
    } catch (e) {
      throw new Error("Error exporting inventory summary")
    }
  }
}
